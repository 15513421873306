@tailwind base;
@tailwind components;
@tailwind utilities;

@media (-webkit-device-pixel-ratio: 1.25) {
    #root {
        zoom: 0.8;
    }

    .min-h-screen, .h-screen {
        height: 125vh!important;
    }
}
@media (-webkit-device-pixel-ratio: 1.5) {
    #root {
        zoom: 0.666666667;
    }

    .min-h-screen, .h-screen {
        height: 150vh!important;
    }
}
/*
body {
    background: url("https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/v960-ning-30.jpg?w=1200&h=1200&dpr=1&fit=clip&crop=default&fm=jpg&q=75&vib=3&con=3&usm=15&cs=srgb&bg=F4F4F3&ixlib=js-2.2.1&s=a133850ec0ea076cc42b0efa22390cd3");
    background: url("https://mir-s3-cdn-cf.behance.net/project_modules/1400/4d845686128681.5d909dad4ddd3.jpg");
    background-size: cover;
}

#root.dark {
    background: url("https://wallpaperaccess.com/full/1092758.jpg");
    background: url("https://wallpaperaccess.com/full/862213.jpg");
    background-size: cover;
}
*/
#root {
    font-family: Poppins, sans-serif;
    display: flex;
    min-height: 100vh;
    position: relative;
    transition: background-color 0.3s ease;
}

.dark {
    background-color: #161b22;
}

.dark div {
    border-color: #30363d;
}

.dark .hover {
    box-shadow: none;
}

.dark h1, .dark h3 {
    color: rgba(255, 255, 255, 0.9);
    font-weight: normal;
}

.dark .darkb {
    background-color: #2b3a50;
    color: rgba(255, 255, 255, 0.9);
    font-weight: normal;
}

.colors {
    margin: 20px 0;
}

.colors li {
    margin: 10px 0;
    cursor: pointer;
    padding: 1rem;
    aspect-ratio: 1;
    height: 34px;
    border-radius: 100%;
}

.hover {

}

.hover:hover {
    transform: scale(1.01);
    transition: all 0.3s ease-in-out;
}

/* Scroll Bar CSS */
html {
    overflow-y: overlay;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
/* Scroll Bar CSS END */

.actions-bar li {
    margin: 0 2px 20px;
}
.actions-bar button > svg {

}
.actions-bar button {
    background-color: transparent;
}

.note-body {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: rgba(0,0,0,0.7);
    width: 100%;
}
.top-bar {
    background: white;
    border-radius: 2rem;
}
.dark .top-bar {
    background: #161b22;
}

.actions-bar {
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 0.25);
    background-color: aliceblue;
}

.notification {
    padding: 16px 24px;
    border-radius: 8px;
    font-size: 14px;
    width: fit-content;
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(30, 144, 255, 0.9);
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 0.25);
    margin-top: 20px;
}

.ani-nots {
    opacity: 0;
    animation: nots 5s;
}

.entrance {
    animation: inn 0.2s;
}

.entrance-slow {
    animation: fin 1s;
}

.grad {
    /*background-image: linear-gradient(to right, #cf3aff, #00ffae);*/
    background: url("https://images.wallpaperscraft.com/image/single/notebook_paper_clips_surface_170140_2560x1080.jpg");
}

.typewriter {
    animation: writer 1s;
}

@keyframes writer {
    from {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 0;
    }

    to {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 100%;
    }

}

@keyframes fin {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }

}

.fade {
    animation: fadeaway 0.3s;
}

input:-webkit-autofill {
    -webkit-background-clip: text;
}

input[type=password] {
    -webkit-text-security: square;
}

@keyframes fadeaway {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes inn {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes nots {
    0% {
        margin-bottom: -20px;
        opacity: 0;
    }
    10% {
        margin-bottom: 0;
        opacity: 1;
    }
    90% {
        margin-bottom: 0;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.success {
    background-color: mediumseagreen;
}

.info {
    background-color: lightcoral;
}
.failed {
    background-color: coral;
}

.icos button {
    margin: 0 10px;
}

.mark {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: scale(2);
}

.blinker {
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        opacity: 0.5;
    }
    25% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }

}

.hofo {
    width: 100%;
    max-width: 350px;
    position: relative;
}
.hofo input {
    width: 100%;
}

#menu li:hover {
    background: rgb(228, 228, 228);
}

#menu {
    width: 200px;
}

#menu a {
    display: flex;
    align-items: center;
    padding: 16px 24px;
}

#menu span {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 0 12px;
}


#noti-stack {
    margin: 2.5rem;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 50;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}